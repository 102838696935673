<template>
  
  <router-view/>
</template>

<style lang="scss">
$primary-color: #4f52ff;

* {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  scroll-behavior: smooth;
}

</style>
