<template>
  <!-- Back to top button -->
  <div class="back-to-top"></div>

  <header>
    <nav class="navbar navbar-expand-lg navbar-light navbar-float">
      <div class="container">
        <img src="../../assets/kkf.png" class="kkf-logo">

        <button class="navbar-toggler" data-toggle="collapse" data-target="#navbarContent" aria-controls="navbarContent" aria-expanded="false" aria-label="Toggle navigation">
          <span class="navbar-toggler-icon"></span>
        </button>

        <div class="navbar-collapse collapse" id="navbarContent">
          <ul class="navbar-nav ml-lg-4 pt-3 pt-lg-0">
            <li class="nav-item active">
              <a href="" class="nav-link">Home</a>
            </li>
            <li class="nav-item">
              <a href="#choose-us" class="nav-link">About</a>
            </li>
            <li class="nav-item">
              <a href="#services" class="nav-link">Services</a>
            </li>
            <li class="nav-item">
              <a href="#projects" class="nav-link">projects</a>
            </li>
            <li class="nav-item">
              <a href="#contact-us" class="nav-link">Contact</a>
            </li>
          </ul>

          <div class="ml-auto">
            <a href="#contact-us" class="btn btn-outline rounded-pill">Get started</a>
          </div>
        </div>
      </div>
    </nav>

    <div class="page-banner home-banner">
      <div class="container h-100">
        <div class="row align-items-center h-100">
          <div class="col-lg-6 py-3 wow fadeInUp">
            <h1 class="mb-4">Kaffir Technology solutions</h1>
            <p class="text-lg mb-2">We help you build your dream software and ideas</p>
            <p class="text-lg mb-5">Are you a start up company? or you need skillful techies?</p>

            <!-- <a href="#" class="btn btn-outline border text-secondary">More Info</a><div class="fab"><span class="mai-play"></span></div> -->
            <a href="#choose-us" class="btn btn-primary ml-2">more info?  </a>
          </div>
          <div class="col-lg-6 py-3 wow zoomIn">
            <div class="img-place">
              <img src="../../assets/img/bg_image_1.png" alt="">
            </div>
          </div>
        </div>
      </div>
    </div>
  </header>

  <main>
    <div class="page-section features" id="services">
      <div class="container">
        <div class="row justify-content-center">
          <div class="col-md-6 col-lg-4 py-3 wow fadeInUp">
            <div class="d-flex flex-row">
              <div class="img-fluid mr-3">
                <img src="../../assets/img/icon_pattern.svg" alt="">
              </div>
              <div>
                <h5>looking to hire developers</h5>  
                <p>we have the best team you can ever imagine</p>
              </div>
            </div>
          </div>
  
          <div class="col-md-6 col-lg-4 py-3 wow fadeInUp">
            <div class="d-flex flex-row">
              <div class="img-fluid mr-3">
                <img src="../../assets/img/icon_pattern.svg" alt="">
              </div>
              <div>
                <h5>you need branding for your business</h5>
                <p>Logos, flyers, branded mug etc.</p>
              </div>
            </div>
          </div>
  
          <div class="col-md-6 col-lg-4 py-3 wow fadeInUp">
            <div class="d-flex flex-row">
              <div class="img-fluid mr-3">
                <img src="../../assets/img/icon_pattern.svg" alt="">
              </div>
              <div>
                <h5>Thinking of kickstarting</h5>
                <p>Let help you get to the top</p>
              </div>
            </div>
          </div>
        </div>
      </div> <!-- .container -->
    </div> <!-- .page-section -->
  
    <div class="page-section">
      <div class="container">
        <div class="row">
          <div class="col-lg-6 py-3 wow zoomIn">
            <div class="img-place text-center">
              <img src="../../assets/img/bg_image_2.png" alt="">
            </div>
          </div>
          <div class="col-lg-6 py-3 wow fadeInRight">
            <h2 class="title-section">We're <span class="marked">Dynamic</span> Team of super Creatives People</h2>
            <div class="divider"></div>
            <p>we provide all kinds of software solutions which include website and app support, mobile application, graphics designs, UI/UX designs, full business branding etc.</p>
            <p>we also help startup organizations achieve their goals.</p>
            <div class="img-place mb-3">
              <img src="../../assets/img/testi_image.png" alt="">
            </div>
            <a href="#" class="btn btn-primary">More Details</a>
            <a href="#" class="btn btn-outline border ml-2">Success Stories</a>
          </div>
        </div>
      </div> <!-- .container -->
    </div> <!-- .page-section -->
  
    
  
    <div class="page-section" id="choose-us">
      <div class="container">
        <div class="row">
          <div class="col-lg-6 py-3 wow fadeInLeft">
            <h2 class="title-section">We're <span class="marked">ready to</span> Serve you with best</h2>
            <div class="divider"></div>
            <p class="mb-5">Expecially if you are a start up company, we have a well package plan that is suitable for your bubget</p>
            <a href="#contact-us" class="btn btn-primary">More Details</a>
            <!-- <a href="#" class="btn btn-outline ml-2">See pricing</a> -->
          </div>
          <div class="col-lg-6 py-3 wow zoomIn">
            <div class="img-place text-center">
              <img src="../../assets/img/bg_image_3.png" alt="">
            </div>
          </div>
        </div>
      </div> <!-- .container -->
    </div> <!-- .page-section -->
  
    <div class="page-section">
      <div class="container">
        <div class="text-center wow fadeInUp">
          <div class="subhead">Why Choose Us</div>
          <h2 class="title-section">Your <span class="marked">Comfort</span> is Our Priority</h2>
          <div class="divider mx-auto"></div>
        </div>
  
        <div class="row mt-5 text-center">
          <div class="col-lg-4 py-3 wow fadeInUp">
            <div class="display-3"><span class="mai-shapes"></span></div>
            <h5>High Performance</h5>
            <p>Here at kaffir we provide high quality performance in all aspect.</p>
          </div>
          <div class="col-lg-4 py-3 wow fadeInUp">
            <div class="display-3"><span class="mai-shapes"></span></div>
            <h5>Friendly Prices</h5>
            <p>Our priceses are very friendly expecially to Start Ups.</p>
          </div>
          <div class="col-lg-4 py-3 wow fadeInUp">
            <div class="display-3"><span class="mai-shapes"></span></div>
            <h5>No time-confusing</h5>
            <p>we are fast and accurate we deliver before due date.</p>
          </div>
        </div>
      </div> <!-- .container -->
    </div> <!-- .page-section -->
     <!-- <div class="page-section bg-light">
      <div class="container">
        
        <div class="owl-carousel wow fadeInUp" id="testimonials">
          <div class="item">
            <div class="row align-items-center">
              <div class="col-md-6 py-3">
                <div class="testi-image">
                  <img src="../../assets/img/person/person_1.jpg" alt="">
                </div>
              </div>
              <div class="col-md-6 py-3">
                <div class="testi-content">
                  <p>The Web as I envisaged it, we have not seen it yet. The future is still so much bigger than the past.
                    Aladesiun temitope a fullstack web developer
                  </p>
                  <div class="entry-footer">
                    <strong>Aladesiun temitope</strong> &mdash; <span class="text-grey">CEO kaffir
                    </span>
                  </div>
                </div>
              </div>
            </div>
          </div>
  
          <div class="item">
            <div class="row align-items-center">
              <div class="col-md-6 py-3">
                <div class="testi-image">
                  <img src="../../assets/img/person/person_2.jpg" alt="">
                </div>
              </div>
              <div class="col-md-6 py-3">
                <div class="testi-content">
                  <p>Repudiandae vero assumenda sequi labore ipsum eos ducimus provident a nam vitae et, dolorum temporibus inventore quaerat consectetur quos! Animi, qui ratione?</p>
                  <div class="entry-footer">
                    <strong>George Burke</strong> &mdash; <span class="text-grey">CEO Letro</span>
                  </div>
                </div>
              </div>
            </div>
          </div>
  
        </div>
      </div> 
    </div>  -->
        <div class="page-section border-top" id="projects">
      <div class="container">
        <div class="text-center wow fadeInUp">
          <div class="subhead">Our Project</div>
          <h2 class="title-section">Few of our <span class="marked">product</span></h2>
          <div class="divider mx-auto"></div>
        </div>
        <div class="row my-5 card-blog-row">
          
          <div class="col-md-6 col-lg-4 py-3 wow fadeInUp">
            <div class="card-blog">
              <div class="header">
                <div class="entry-footer">
                  <div class="post-author">Resume generator</div>
                  <a href="#" class="post-date">from Dec 2021</a>
                </div>
              </div>
              <div class="body">
                <div class="post-title cappitalize"><a href="https://resume.kaffir.ng" class="capitalize">this app will help you generate your resume to get a job</a></div>
              </div>
              <div class="footer">
                <a href="https://resume.kaffir.ng" target="_blank" >Check it out <span class="mai-chevron-forward text-sm"></span></a>
              </div>
            </div>
          </div>
           
          <div class="col-md-6 col-lg-4 py-3 wow fadeInUp">
            <div class="card-blog">
              <div class="header">
                <!-- <div class="avatar">
                  <img src="../../assets/img/person/person_1.jpg" alt="">
                </div> -->
                <div class="entry-footer">
                  <div class="post-author">Anonymous messaging</div>
                  <a href="#" class="post-date">From  july 2019</a>
                </div>
              </div>
              <div class="body">
                <div class="post-title"><a href="https://anonymous.kaffir.ng" target="_blank">What is Business Management?</a></div>
                <div class="post-excerpt">Lorem, ipsum dolor sit amet consectetur adipisicing elit.</div>
              </div>
              <div class="footer">
                <a href="https://anonymous.kaffir.ng" target="_blank">Check it out <span class="mai-chevron-forward text-sm"></span></a>
              </div>
            </div>
          </div>
           <div class="col-md-6 col-lg-4 py-3 wow fadeInUp">
            <div class="card-blog">
              <div class="header">
                <div class="entry-footer">
                  <div class="post-author">Kaffir Academy</div>
                  <a href="#" class="post-date">upcoming</a>
                </div>
              </div>
              <div class="body">
                <div class="post-title cappitalize"><a href="#" class="capitalize">learn any digital skills from us</a></div>
              </div>
              <div class="footer">
                <a href="#" target="_blank" >Check it out <span class="mai-chevron-forward text-sm"></span></a>
              </div>
            </div>
          </div>
        </div>
  
        <!-- <div class="text-center">
          <a href="blog.html" class="btn btn-outline-primary rounded-pill">Discover More</a>
        </div> -->
      </div> <!-- .container -->
    </div> <!-- .page-section -->
    <contact/>
    <!-- .page-section -->
  

  
  </main>

  <footer class="page-footer">
    <div class="container">
      <div class="row justify-content-center mb-5">
        <div class="col-lg-3 py-3">
          <h3>ka<span class="text-primary">ff</span>ir</h3>
        <!-- <img src="../../assets/kkf.png" class="kkf-logo"> -->

          <p> Trust us it's going to be enthausating. Connect and make it beautiful.    </p>

          <p><a href="#" >kaffirconcept@gmail.com</a></p>
          <p><a href="#">+234 37019967</a></p>
        </div>
        <div class="col-lg-3 py-3">
          <h5>Quick Links</h5>
          <ul class="footer-menu">
            <li><a href="#">How it works</a></li>
            <li><a href="#">Security</a></li>
            <li><a href="#">Pricing</a></li>
            <li><a href="#">Resources</a></li>
            <li><a href="#contact-us">Report a Bug</a></li>
          </ul>
        </div>
        <div class="col-lg-3 py-3">
          <h5>About Us</h5>
          <ul class="footer-menu">
            <li><a href="#">About Us</a></li>
            <li><a href="#" disabled>Our Teams</a></li>
            <li><a href="#">Testimonials</a></li>
            <li><a href="#">News & Press</a></li>
          </ul>
        </div>
        <div class="col-lg-3 py-3">
          <h5>Subscribe</h5>
          <form action="#" class="d-flex">
            <input type="text" class="form-control" placeholder="Enter your mail..">
            <button type="submit" class="btn btn-primary ml-2">submit</button>

            <!-- <button type="submi">submit</button> -->
          </form>

          <div class="sosmed-button mt-4">
            <a href="#"><span class="mai-logo-facebook-f"></span></a>
            <a href="https://twitter.com/Temitopeluke"><span class="mai-logo-twitter"></span></a>
            <a href="https://www.linkedin.com/in/kaffir-concept-736693231/" target="_blank"><span class="mai-logo-linkedin"></span></a>
          </div>
        </div>
      </div>

      <div class="row">
        <div class="col-sm-6 py-2">
          <p id="copyright">&copy; 2022 <a href="https://kaffir.ng/">KAFFIR</a>. All rights reserved</p>
        </div>
        <div class="col-sm-6 py-2 text-right">
          <div class="d-inline-block px-3">
            <a href="#">Privacy</a>
          </div>
          <div class="d-inline-block px-3">
            <a href="#">Contact Us</a>
          </div>
        </div>
      </div>
    </div> <!-- .container -->
  </footer> <!-- .page-footer -->


 


</template>
<script>
import contact from "./contact.vue"
export default{
  components:{contact}

}
</script>